import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

export default function TokenGuard(props) {

    const navigate = useNavigate();
    const [token, setToken] = useState(authService.getToken());
    const [isPremium, setIsPremium] = useState(authService.getIsPremium());


    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [token])



    if (!isPremium) {
        return (
            <div>הכניסה ללקוחות פרימיום בלבד</div>
        )
    }


    return (
        token &&
        <>
            {props.children}
        </>
    )
}