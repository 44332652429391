import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import SbsTable from "../../components/SbsTable"
import { menuService } from "../../services/menuService";
import { planService } from "../../services/planService";

export default function Menu() {

    useEffect(() => {
        document.title = "Step By Step - Menu";
        loadMenu();
    }, [])

    const [menu, setMenu] = useState(null)
    const navigate = useNavigate()
    const typeHebrew = {
        Carbohydrate: 'פחמימות',
        Protein: 'חלבונים',
        Fat: 'שומנים',
    }

    const loadMenu = async () => {
        try {
            const menu = await menuService.get();
            setMenu(menu);
        } catch {
            setTimeout(() => {
                navigate('/')
            }, 5000);
        }
    }

    const dowland = async () => {
        const menu_id = menu.id;
        const URL = `//sbs.server.barpahima.co.il/api/nutrition/menus/pdf/${menu_id}`;
        window.open(URL, '_blank');
        // const dowland = await menuService.dowland(menu_id);
        // console.log(dowland);
    }

    return (
        <div className="main-container">
            <div className="menu-page page flex column gap-1">
                {
                    menu &&
                    <div>
                        <button onClick={dowland} className="button-primary">
                            הורד
                        </button>
                    </div>
                }
                <div className="menu-container w100 ">
                    <div className="menu w100 flex column gap-2">
                        {
                            menu && menu.meals.map(meal => {

                                const table = meal.items.map((item) => {
                                    return {
                                        ...item,
                                        type: typeHebrew[item.type],
                                    }
                                })

                                return (
                                    <div className="meal-container">
                                        <div className="meal-title">
                                            {meal.title}
                                        </div>
                                        <div className="meal-products">
                                            <SbsTable
                                                columnMap={{ name: 'שם המוצר', type: 'סוג המוצר', amount: 'כמות', customAmount: 'כמות במנות' }}
                                                isHaveOptions={false}
                                                metaData={table}
                                                options={[
                                                    // { icon: 'add', cb: onOptionAddProduct },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            (!menu) &&
                            <div
                                className="flex column gap-1 align-center"
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "red",
                                        fontSize: '30px'
                                    }}
                                >
                                    לא נמצא תוכנית תזונה פעילה
                                </span>
                                <button className="button-primary" onClick={() => navigate('/')} >חזור לבית</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}