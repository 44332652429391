import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import SbsIcons from "../../components/SbsIcons"
import { logbookService } from "../../services/logbookService";
import { utiService } from "../../services/utiService";
import AskDeletePopup from "./components/AskDeletePopup";

export default function LogBook() {

    const [selectedDate, setSelectedDate] = useState(utiService.timestampToStartDay(Date.now()));
    const [today, setToday] = useState(utiService.timestampToStartDay(Date.now()));
    const [logbook, setLogbook] = useState(logbookService.getEmpty())
    const [logbookList, setLogbookList] = useState([])
    const [askDeleteIdx, setAskDeleteIdx] = useState(-1)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Step By Step - Logbook";
        setLogbook(() => {
            const currLogbook = logbookList.find(logbookItem => logbookItem.date === selectedDate);
            return currLogbook ?? { ...logbookService.getEmpty(), date: selectedDate }
        })
    }, [selectedDate, logbookList])

    useEffect(() => {
        updateLogbook()
    }, [])


    const save = async () => {
        await logbookService.save(logbook);
        navigate('/');

    }

    const updateLogbook = async () => {
        const logbookList = await logbookService.get();
        setLogbookList(logbookList);
    }

    const addExecise = () => {
        setLogbook({ ...logbook, exercises: [...logbook.exercises, logbookService.getEmptyExercise()] })
    }
    const removeExecise = () => {
        setLogbook({ ...logbook, exercises: logbook.exercises.filter((_, i) => askDeleteIdx !== i) })
        setAskDeleteIdx(-1)
    }

    const nextDay = () => {
        setSelectedDate(utiService.timestampToStartDay(selectedDate + 1000 * 60 * 60 * 24))
    }
    const prevDay = () => {
        setSelectedDate(utiService.timestampToStartDay(selectedDate - 1000 * 60 * 60 * 24))
    }



    return (
        <div className="main-container">
            <div className="log-book-page page flex column gap-1">
                <div className="page-header flex align-center gap-1">
                    <SbsIcons icon={'logbook'} />
                    <div className="grow-1">
                        <div className="flex justify-space-between" >
                            <span>לוג בוק</span>
                            <div className="flex gap-1 align-center">
                                <button onClick={() => { nextDay() }} className="clean-button"><SbsIcons icon={'right'} /></button>
                                <div className="hidden-timepicker">
                                    <label htmlFor="timestamp" ><span >{utiService.timestampToDDMMYYYY(selectedDate)}</span></label>
                                    <input
                                        type="date"
                                        id="timestamp"
                                        onFocus={(ev) => ev.target.showPicker()}
                                        name="timestamp"
                                        value={utiService.timestampToDatePickerTime(selectedDate)}
                                        onChange={({ target }) => { setSelectedDate(utiService.datePickerTimeToTimestamp(target.value)) }}
                                    />
                                </div>
                                <button onClick={() => { prevDay() }} className="clean-button"><SbsIcons icon={'left'} /> </button>
                            </div>

                        </div>
                        <span>תוכנית אימונים מספר 123</span>
                    </div>

                </div>
                <div className={`data-container flex column gap-1 ${selectedDate === today ? '' : ''}`}>
                    <textarea
                        disabled={selectedDate !== today}
                        className='sbs-input'
                        value={logbook.remark}
                        onChange={({ target }) => { setLogbook({ ...logbook, remark: target.value }) }}
                        placeholder="הערות"
                    />

                </div>
                {
                    logbook.exercises.map((exercise, idx) => (
                        <div key={idx} className="exercise-data flex column gap-1">
                            <div className="flex gap-1">

                                <input
                                    disabled={selectedDate !== today}
                                    className='sbs-input grow'
                                    type="text"
                                    value={logbook.exercises[idx].title}
                                    onChange={({ target }) => {
                                        setLogbook(() => {
                                            let newExercises = [...logbook.exercises];
                                            newExercises[idx].title = target.value;
                                            return { ...logbook, exercises: newExercises }
                                        })
                                    }}
                                    placeholder="תרגיל"
                                />
                                <div onClick={() => { setAskDeleteIdx(idx) }} className={`button-secondary remove ${selectedDate !== today ? 'disabled' : ''}`}>מחק</div>
                            </div>
                            <div className="repeat gap-1 flex align-center">
                                <span>חזרות</span>
                                <div className="flex gap-1 grow">
                                    {
                                        logbook.exercises[idx].sets.map((set, eIdx) => (
                                            <div key={eIdx} className="exerciseflex grow">
                                                <input
                                                    disabled={selectedDate !== today}
                                                    className='sbs-input grow'
                                                    type="number"
                                                    value={logbook.exercises[idx].sets[eIdx].repeat}
                                                    onChange={({ target }) => {
                                                        setLogbook(() => {
                                                            let newExercises = [...logbook.exercises];
                                                            newExercises[idx].sets[eIdx].repeat = +target.value;
                                                            return { ...logbook, exercises: newExercises }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        ))
                                    }



                                </div>

                            </div>
                            <div className="weight gap-1 flex align-center">
                                <span>משקל</span>
                                <div className="flex gap-1 grow">
                                    {
                                        logbook.exercises[idx].sets.map((set, eIdx) => (
                                            <div key={eIdx} className="exerciseflex grow">
                                                <input
                                                    disabled={selectedDate !== today}
                                                    className='sbs-input grow'
                                                    type="number"
                                                    value={logbook.exercises[idx].sets[eIdx].weight}
                                                    onChange={({ target }) => {
                                                        setLogbook(() => {
                                                            let newExercises = [...logbook.exercises];
                                                            newExercises[idx].sets[eIdx].weight = +target.value;
                                                            return { ...logbook, exercises: newExercises }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        ))
                                    }



                                </div>

                            </div>

                        </div>
                    ))
                }
                <div className="flex gap-1">
                    <button disabled={selectedDate !== today} onClick={() => { addExecise() }} className="button-primary">הוסף תרגיל</button>
                    <button disabled={selectedDate !== today} onClick={() => { save() }} className="button-primary">שמור</button>
                </div>

            </div>

            <AskDeletePopup
                deleteExecise={removeExecise}
                execiseIdx={askDeleteIdx}
                setExeciseIdx={setAskDeleteIdx}
            />
        </div>
    )
}