import Axios from 'axios';
import { authService } from './authService';

const BASE_URL = process.env.NODE_ENV === 'production'
    // ? '/api/'
   ? '//sbs.server.barpahima.co.il/api/'
    : '//sbs.server.barpahima.co.il/api/'
    // ? '//2243-80-178-138-34.ngrok.io/api/'
    // : '//2243-80-178-138-34.ngrok.io/api/'


var axios = Axios.create({
    withCredentials: false
});

axios.interceptors.request.use(function (config) {
    const token = authService.getToken();

    config.headers.Authorization = token;
    return config;
});

export default {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    },

}

async function ajax(endpoint, method = 'get', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data
        })
        return res.data;
    } catch (err) {
        console.log(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data: ${data}`);
        console.dir(err);
        if (err.response && err.response.status === 401) {
            // window.location.href = 'http://a806-2-54-188-169.ngrok.io/login'
        }
        throw err;
    }
}
