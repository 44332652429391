import httpService from "./httpService";

export const menuService = {
    get,
    dowland
}

async function get() {
    const res = await httpService.get('customers/menu');
    return res.data
}
async function dowland(menu_id) {
    const res = await httpService.get(`nutrition/menus/pdf/${menu_id}`);
    return res
}