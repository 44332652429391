import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { authService } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"
import SbsIcons from "../../components/SbsIcons"
import logo from '../../assets/imgs/logo.png'

export default function Lobby() {
    const [user, setUser] = useState(null)
    const [greeting, setGreeting] = useState('שלום');
    const location = useLocation();
    const navigate = useNavigate();

    const buttonsData = [
        {
            text: 'תוכניות תזונה',
            icon: 'nutrition',
            route: '/menu',
            id: 1
        },
        {
            text: 'תוכניות אימונים',
            icon: 'training',
            route: '/plan',
            id: 2
        },
        {
            text: 'לוג בוק',
            icon: 'logbook',
            route: '/log_book',
            id: 5
        },
    ]

    useEffect(() => {
        document.title = "Step By Step - Lobby";
        updateGreetings();
        const token = authService.getToken();
        if (!token && location.pathname !== '/login') navigate('/login')
        else if (token && !user) {
            updateUser()
        }
    }, [location])

    const updateGreetings = () => {
        const now = new Date();
        const hour = now.getHours()
        if (hour < 12) setGreeting('בוקר טוב')
        else if (hour < 14) setGreeting('צהריים טובים')
        else if (hour < 17) setGreeting('אחר צהריים טובים')
        else if (hour < 22) setGreeting('ערב טוב')
        else setGreeting('לילה טוב')

    }

    const updateUser = async () => {
        const user = await authService.getUser();
        setUser(user)
    }

    const helpLink = async () => {
        window.open("https://wa.me/972507605259", "_blank");
    }

    const updateLink = async () => {
        window.open("https://barpahima.co.il/update/", "_blank");
    }

    return (
        <div className="main-container">
            <div className="lobby-page page">
                <div className="flex column gap-1" >
                    <img className="logo" src={logo} alt="sbs-logo" />
                    <span style={{ alignSelf: "center" }}>{greeting}, {user?.firstName} {user?.lastName}</span>
                    <div className="buttons-container">
                        {
                            buttonsData.map(data => (
                                <Link
                                    key={data.id}
                                    to={data.route}
                                >
                                    <SbsIcons icon={data.icon} />
                                    {data.text}
                                </Link>
                            ))
                        }
                        <a
                            key="3"
                            onClick={updateLink}
                        >
                            <SbsIcons icon={"plane"} />
                            טופס עדכון
                        </a>
                        <a
                            key="4"
                            onClick={helpLink}
                        >
                            <SbsIcons icon={"help"} />
                            צור קשר
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}