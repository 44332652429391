
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import * as GrIcons from 'react-icons/gr';
import * as GiIcons from 'react-icons/gi';
import * as ImIcons from 'react-icons/im';
import * as BsIcons from 'react-icons/bs';
import * as FiIcons from 'react-icons/fi';
export default function SbsIcons({ icon }) {
    switch (icon) {
        case 'delete':
            return <AiIcons.AiFillDelete />
        case 'edit':
            return <FaIcons.FaEdit />
        case 'add':
            return <IoIcons.IoIosAdd />
        case 'close':
            return <GrIcons.GrClose />
        case 'personalInformation':
            return <AiIcons.AiOutlineUser />
        case 'training':
            return <GiIcons.GiWeightLiftingUp />
        case 'nutrition':
            return <MdIcons.MdOutlineFoodBank />
        case 'files':
            return <ImIcons.ImFilesEmpty />
        case 'permissionsManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'employeeManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'userManagment':
            return <MdIcons.MdOutlineManageAccounts />
        case 'paymentManagment':
            return <MdIcons.MdPayment />
        case 'check':
            return <BsIcons.BsCheckCircle />
        case 'update':
            return <GrIcons.GrUpdate />
        case 'youtube':
            return <FaIcons.FaYoutube />
        case 'load':
            return <AiIcons.AiOutlineCloudUpload />
        case 'users':
            return <FiIcons.FiUsers />
        case 'user':
            return <AiIcons.AiOutlineUser />
        case 'clock':
            return <BsIcons.BsClock />
        case 'date':
            return <BsIcons.BsCalendar2Date />
        case 'play':
            return <BsIcons.BsFillPlayFill />
        case 'pause':
            return <BsIcons.BsPauseFill />
        case 'logout':
            return <FiIcons.FiLogOut />
        case 'plane':
            return <FaIcons.FaTelegramPlane />
        case 'help':
            return <FiIcons.FiHelpCircle />
        case 'logbook':
            return <AiIcons.AiOutlineBook />
        case 'right':
            return <MdIcons.MdArrowRight />
        case 'left':
            return <MdIcons.MdArrowLeft />
        case 'home':
            return <AiIcons.AiOutlineHome />

        default:
            return <BsIcons.BsPauseFill />
    }
}