import { useEffect, useState } from "react";
import SbsIcons from "../../../components/SbsIcons";
import { utiService } from "../../../services/utiService";

export default function AskDeletePopup({ deleteExecise, execiseIdx, setExeciseIdx, }) {

    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        if (execiseIdx >= 0) setIsOpen(true);
        else setIsOpen(false);
    }, [execiseIdx])

    return (
        <div onClick={() => setExeciseIdx(-1)} className={isOpen ? 'sbs-popup-container open' : 'sbs-popup-container close'}>
            <div onClick={(ev) => utiService.stopPropagation(ev)} className='sbs-popup'>
                <div className='popup-header'>
                    <span>אתה בטוח שאתה רוצה למחוק</span>
                    <div className='close-popup' onClick={() => setExeciseIdx(-1)} >
                        <SbsIcons icon="close"></SbsIcons>
                    </div>
                </div>
                <div className='popup-body'>
                    {/* <span> אתה בטוח שאתה רוצה למחוק את המשפט {askDeleteSentence?.sentence} ששייך לקבוצות {askDeleteSentence?.groups}</span> */}
                </div>
                <div className='popup-footer'>
                    <button className='button-primary' onClick={() => deleteExecise()} >כן</button>
                    <button className='button-secondary' onClick={() => setExeciseIdx(-1)} >לא</button>
                </div>
            </div>
        </div>
    )
}