import { useEffect, useState } from "react"
import VerifyCode from "../../components/VerifyCode";
import { authService } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/imgs/logo.png'

export default function Login() {

    const [isMsgSend, setIsMsgSend] = useState(false);
    const [phone, setPhone] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Step By Step - Login";
        if (authService.getToken()) {
            navigate('/')
        }
    }, [])



    const onSendCode = async () => {
        setIsLoad(true)
        try {
            await authService.sendCode(phone);
            setIsMsgSend(true);
            setIsLoad(false)
        } catch {
            setIsLoad(false)
            setIsError(true)
        }
    }

    const onChangePhone = () => {
        setIsMsgSend(false);
        setPhone('');
    }

    const onLogin = async () => {
        setIsLoad(true)
        try {

            await authService.login(phone, verifyCode);
            navigate('/')
        } catch {
            setIsLoad(false)
        }

    }


    return (
        <div className="main-container">
            <div className="login-page page" >
                <div className="flex column gap-1 align-center justify-center">
                    <img src={logo} alt={"sbs-logo"} />
                    {
                        isMsgSend ? (
                            <>
                                <span className="sbs-subtitle" >הזן את הקוד שקיבלת</span>
                                <VerifyCode
                                    setVerifyCode={setVerifyCode}
                                    enterCallBack={() => { if (verifyCode.length === 5) onLogin() }}
                                />
                                <div className="flex justify-content-center gap-1">
                                    <button
                                        className="button-primary"
                                        onClick={() => { onLogin() }}
                                    >
                                        התחבר
                                    </button>
                                    <button
                                        onClick={() => { onChangePhone() }}
                                        className="button-primary"
                                    >
                                        שינוי טלפון
                                    </button>
                                </div>
                                <button onClick={() => onSendCode()} className="default-link" >לא קיבלתי קוד</button>
                            </>
                        ) : (

                            <>
                                <span className="sbs-subtitle" >הזן את מספר הטלפון</span>
                                <input
                                    className='sbs-input'
                                    type="text"
                                    value={phone}
                                    onChange={({ target }) => { setPhone(target.value) }}
                                    placeholder="מספר טלפון"
                                    onKeyDown={(ev) => { if (ev.code === 'Enter') onSendCode() }}

                                />
                                {
                                    isError && <span className="error-msg" >מספר הטלפון שהזנת אינו תקין</span>
                                }
                                <button
                                    className="button-primary"
                                    onClick={() => { onSendCode() }}
                                    disabled={isLoad}
                                >
                                    שלח קוד
                                </button>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )
}