import httpService from "./httpService";
import { utiService } from "./utiService";

export const logbookService = {
    get,
    getEmpty,
    getEmptyExercise,
    save
}



function getEmpty() {
    return {
        date: utiService.timestampToStartDay(Date.now()),
        remark: '',
        exercises: [
            getEmptyExercise(),
        ]
    }
}

function getEmptyExercise() {
    return {
        title: '',
        sets: [
            { repeat: 0, weight: 0 },
            { repeat: 0, weight: 0 },
            { repeat: 0, weight: 0 },
            { repeat: 0, weight: 0 },
        ]
    }
}

const ENDPOINT = 'customers/logbooks';


async function get() {
    const res = await httpService.get(ENDPOINT);
    console.log(res);
    return res.data;
}

async function save(savedBookLog) {
    const res = await httpService.put(ENDPOINT, savedBookLog);
}






