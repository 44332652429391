import React, { useEffect, useState } from "react";
import { planService } from "../../services/planService";
import { useNavigate } from "react-router-dom";
import SbsIcons from "../../components/SbsIcons";

export function Plan() {
    const navigate = useNavigate()

    const columnMap = {
        execiseId: 'שם התרגיל',
        numSet: 'סטים',
        numRepeat: 'חזרות',
        type: "סוג",
        description: 'תיאור',
        youtube: 'יוטיוב'
    }

    const [execiseMap, setExeciseMap] = useState(null);
    const [plan, setPlan] = useState(null)

    useEffect(() => {
        document.title = "Step By Step - Plan";
        loadPlan();
        updateExecisesMap()
    }, [])

    const loadPlan = async () => {
        const plan = await planService.get();
        setPlan(plan);
    }

    const updateExecisesMap = async () => {
        const execises = await planService.getExcises();
        const map = execises.reduce((acc, execise) => {
            acc[execise.id] = execise
            return acc
        }, {})
        setExeciseMap(map)
    }

    const dowland = async () => {
        const plan_id = plan.id;
        const URL = `//sbs.server.barpahima.co.il/api/training/plans/pdf/${plan_id}`;
        window.open(URL, '_blank');
        // const dowland = await menuService.dowland(menu_id);
        // console.log(dowland);
    }


    return (
        <div className="main-container">
            <div className="plan-page page flex column gap-1">
                {
                    plan &&
                    <div>
                        <button onClick={dowland} className="button-primary">
                            הורד
                        </button>
                    </div>
                }
                <div>
                    {
                        plan && plan.trains.map((train, idx) => (
                            <div className="flex column gap-1" key={idx} >
                                <span className="sbs-title">אימון {train.title}</span>
                                <table className='sbs-table'>
                                    <tbody>
                                        <tr>
                                            {
                                                Object.values(columnMap).map((columnName, idx) => (
                                                    <th key={idx}>{columnName}</th>
                                                ))
                                            }
                                        </tr>
                                        {
                                            train.exerciseTypesOrder.map(exerciseTypeId => {
                                                const exerciseType = train.exerciseTypesMap[exerciseTypeId];
                                                return (
                                                    <React.Fragment key={exerciseTypeId}>
                                                        <tr>
                                                            <td align="start" className="table-colspan" colSpan="6">{exerciseType.title}</td>
                                                        </tr>
                                                        {
                                                            exerciseType.execiseIds.map(execiseId => {
                                                                const trainExercise = train.trainExercisesMap[execiseId]
                                                                return (
                                                                    <tr key={execiseId}>
                                                                        {!!execiseMap &&
                                                                            <td>{execiseMap[trainExercise.execiseId ?? trainExercise.exerciseId]?.title}</td>
                                                                        }
                                                                        <td>{trainExercise.type}</td>
                                                                        <td>{trainExercise.numSet} סטים</td>
                                                                        <td>{trainExercise.numRepeat} חזרות</td>
                                                                        <td>{trainExercise.description}</td>
                                                                        <td>
                                                                            {
                                                                                execiseMap && execiseMap[trainExercise.execiseId ?? trainExercise.exerciseId]?.youtubeUrl &&
                                                                                <a
                                                                                    className="button-primary"
                                                                                    href={execiseMap[trainExercise.execiseId ?? trainExercise.exerciseId]?.youtubeUrl}
                                                                                    target="_blank"
                                                                                >
                                                                                    <SbsIcons icon="youtube" />
                                                                                </a>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ))
                    }
                    {
                        (!plan) ? <div style={{ display: "flex", justifyContent: "center", color: "red" }}>לא נמצא תוכנית כושר פעילה</div> : <></>
                    }
                </div>
            </div>
        </div>
    )
}