import httpService from "./httpService";

export const planService = {
    get,
    getExcises
}

async function get() {
    const res = await httpService.get('customers/plan');
    console.log(res);
    return getOrDamriData(res.data);
}

async function getExcises() {
    const res = await httpService.get('customers/exercises');
    const exercises = res.data.map(exercise => {
        const { id, type, name, description, video, image } = exercise;
        return { id, type, title: name, description, youtubeUrl: video, image }
    });
    return exercises
}

function getOrDamriData(nivTemplate) {
    const { id, title, trains, creatorName, customerID } = nivTemplate;
    const template = {
        id: id + '',
        title,
        createBy: creatorName,
        customerId: customerID,
        trains: trains.map(train => {
            const { id, title, trainCategories } = train;
            return {
                id: id + '',
                title,
                exerciseTypesMap: trainCategories
                    .map(trainCategory => {
                        const { type, trainCategoryExercises } = trainCategory;
                        return {
                            id: type,
                            title: type,
                            execiseIds: trainCategoryExercises
                                .sort((trainCategoryExercises, trainCategoryExercises2) => trainCategoryExercises2.index - trainCategoryExercises.index)
                                .map(trainCategoryExercises => '' + trainCategoryExercises.id)
                        }
                    })
                    .reduce((acc, trainCategory) => {
                        acc[trainCategory.id] = trainCategory;
                        return acc
                    }, {}),
                trainExercisesMap: trainCategories.reduce((acc, trainCategory) => {
                    trainCategory.trainCategoryExercises.forEach(trainCategoryExercise => {
                        acc[trainCategoryExercise.id] = { ...trainCategoryExercise, id: '' + trainCategoryExercise.id, exerciseID: '' + trainCategoryExercise.exerciseID, exerciseId: '' + trainCategoryExercise.exerciseID }
                    })
                    return acc
                }, {}),
                exerciseTypesOrder: trainCategories
                    .sort((trainCategory, trainCategory2) => trainCategory2.index - trainCategory.index)
                    .map(trainCategory => trainCategory.type),
            }
        })
    }
    return { ...nivTemplate, ...template };
}