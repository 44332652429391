import { BrowserRouter as Router, Routes, Route, useSearchParams } from "react-router-dom";
import './assets/styles/global.scss';
import Navbar from "./components/Navbar";
import TokenGuard from "./guards/TokenGuard";
import Lobby from "./pages/lobby/Lobby";
import LogBook from "./pages/log-book/LogBook";
import Login from "./pages/login/Login";
import Menu from "./pages/menu/Menu";
import { Plan } from "./pages/plan/Plan";


function App() {
  return (
    <div dir="rtl" className="App">
      <Router>
      <Navbar />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/" element={<TokenGuard><Lobby /></TokenGuard>} />
          <Route exact path="/log_book" element={<TokenGuard><LogBook /></TokenGuard>} />
          <Route exact path="/menu" element={<TokenGuard><Menu /></TokenGuard>} />
          <Route exact path="/plan" element={<TokenGuard><Plan /></TokenGuard>} />
          {/* <Route exact path="/inputs" element={<Inputs />} />
          <Route exact path="/tabs" element={<Tabs />} />
          <Route exact path="/accordion" element={<Accordion />} />
          <Route exact path="/popups" element={<Popups />} />
          <Route exact path="/tables" element={<Tables />} />
          <Route exact path="/editor" element={<Editor />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
