import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { authService } from "../services/authService";
import { useNavigate } from "react-router-dom";
import SbsIcons from "./SbsIcons";


export default function Navbar() {

    const [user, setUser] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = authService.getToken();
        if (!token && location.pathname !== '/login') navigate('/login')
        else if (token && !user) {
            updateUser()
        }
    }, [location])

    const updateUser = async () => {
        const user = await authService.getUser();
        setUser(user)
    }

    const onLogout = () => {
        authService.logout();
        setUser(null)
        navigate('/login')
    }

    if (!user) {
        return <div></div>
    }

    return (
        <div className="navbar">
            <button onClick={() => { navigate('/') }} ><SbsIcons icon="home" /></button>
            <button className="flex align-center gap-1 logout-button" onClick={() => { onLogout() }} >יציאה <SbsIcons icon="logout" /></button>

        </div>
    )
}