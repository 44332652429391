import { useEffect, useState } from 'react';
import SbsIcons from './SbsIcons';


export default function SbsTable({
    metaData,
    columnMap,
    isHaveOptions,
    options,
    isHavePaginator,
    isLoad
}) {

    const PAGE_ITEMS = 25;
    const [pages, setPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(5);

    useEffect(() => {
        setPages(Math.ceil(metaData.length / PAGE_ITEMS));
        setSelectedPage(0)
    }, [metaData])


    // const onClickFirstInRow = (idx, id) => {
    //     if (idx === 0 && cb) cb(id)
    // }

    return (
        <div className='sbs-table-container' >
            <table className='sbs-table'>
                <thead>
                    <tr>
                        {
                            Object.values(columnMap).map((columnName, idx) => (
                                <th key={idx}>{columnName}</th>
                            ))
                        }
                        {isHaveOptions && <th>אופציות</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        (isHavePaginator ? metaData.slice(selectedPage * PAGE_ITEMS, Math.min(metaData.length,(selectedPage * PAGE_ITEMS) + PAGE_ITEMS)):metaData).map((data, idx) => (
                            <tr key={idx} >
                                {
                                    Object.keys(columnMap).map((key, idx) => (

                                        <td key={key} >{data[key]}</td>
                                    ))
                                }
                                {
                                    isHaveOptions &&
                                    <td className='options'>
                                        {
                                            options.map((option, idx) => (
                                                <button className='button-primary' key={idx} onClick={() => option.cb(data.id)} title={option.icon} >
                                                    <SbsIcons icon={option.icon} />
                                                </button>
                                            ))
                                        }
                                    </td>
                                }
                            </tr>
                        ))
                    }
                    {
                        isLoad &&
                        <tr>
                            <td colSpan={Object.keys(columnMap).length + (isHaveOptions ? 1 : 0)} ><div className='loader' ></div></td>
                        </tr>
                    }
                    {
                        !metaData.length && !isLoad &&
                        <tr>
                            <td colSpan={Object.keys(columnMap).length + (isHaveOptions ? 1 : 0)} >אין מידע להציג</td>
                        </tr>
                    }

                </tbody>
            </table>
            {
                isHavePaginator && pages>1 && (
                    <div className='paginator'>
                        {
                            pages <= 7 && (
                                Array.from(Array(pages).keys()).map(page => (
                                    <div
                                        key={page}
                                        className={`page ${page === selectedPage ? 'curr' : ''}`}
                                        onClick={() => setSelectedPage(page)}
                                    >
                                        {page + 1}
                                    </div>
                                ))

                            )
                        }
                        {
                            pages > 7 && (
                                selectedPage > 3 && selectedPage < pages - 4 && (
                                    <>
                                        <div
                                            onClick={() => setSelectedPage(0)}
                                            className={`page ${0 === selectedPage ? 'curr' : ''}`}
                                        >
                                            1
                                        </div>
                                        <span>...</span>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage - 2)}
                                            className={`page`}
                                        >
                                            {selectedPage - 1}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage - 1)}
                                            className={`page`}
                                        >
                                            {selectedPage}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage)}
                                            className={`page curr`}
                                        >
                                            {selectedPage + 1}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage + 1)}
                                            className={`page`}
                                        >
                                            {selectedPage + 2}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage + 2)}
                                            className={`page`}
                                        >
                                            {selectedPage + 3}
                                        </div>
                                        <span>...</span>
                                        <div
                                            onClick={() => setSelectedPage(pages - 1)}
                                            className={`page ${0 === pages - 1 ? 'curr' : ''}`}
                                        >
                                            {pages}
                                        </div>
                                    </>
                                )
                                ||
                                selectedPage <= 3 && (
                                    <>
                                        {


                                            Array.from(Array(selectedPage + 1).keys()).map(page => (
                                                <div
                                                    key={page}
                                                    className={`page ${page === selectedPage ? 'curr' : ''}`}
                                                    onClick={() => setSelectedPage(page)}
                                                >
                                                    {page + 1}
                                                </div>
                                            ))
                                        }
                                        <div
                                            onClick={() => setSelectedPage(selectedPage + 1)}
                                            className={`page`}
                                        >
                                            {selectedPage + 2}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage + 2)}
                                            className={`page`}
                                        >
                                            {selectedPage + 3}
                                        </div>
                                        <span>...</span>
                                        <div
                                            onClick={() => setSelectedPage(pages - 1)}
                                            className={`page ${0 === pages - 1 ? 'curr' : ''}`}
                                        >
                                            {pages}
                                        </div>
                                    </>
                                )
                                ||
                                selectedPage >= pages - 4 && (
                                    <>
                                        <div
                                            onClick={() => setSelectedPage(0)}
                                            className={`page ${0 === selectedPage ? 'curr' : ''}`}
                                        >
                                            1
                                        </div>
                                        <span>...</span>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage - 2)}
                                            className={`page`}
                                        >
                                            {selectedPage - 1}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage - 1)}
                                            className={`page`}
                                        >
                                            {selectedPage}
                                        </div>
                                        <div
                                            onClick={() => setSelectedPage(selectedPage)}
                                            className={`page curr`}
                                        >
                                            {selectedPage + 1}
                                        </div>
                                        {
                                            Array.from(Array(pages - selectedPage - 1).keys()).reverse().map(pageSub => (
                                                <div
                                                    key={pageSub}
                                                    className={`page`}
                                                    onClick={() => setSelectedPage(pages - pageSub-1)}
                                                >
                                                    {pages - pageSub}
                                                </div>
                                            ))
                                        }
                                    </>
                                )


                            )
                        }
                    </div>
                )
            }
            <div></div>
        </div >
    )
}