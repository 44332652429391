import httpService from "./httpService";

export const authService = {
    getToken,
    sendCode,
    login,
    getUser,
    logout,
    getIsPremium
}

let currToken = ''
let isPremium = undefined;
// let currUser = null;

function getToken() {
    const storageToken = sessionStorage.getItem('token');
    if (!currToken && storageToken) currToken = storageToken
    return currToken
}

function getIsPremium() {
    const storageIsPremium = sessionStorage.getItem('isPremium');
    if (isPremium === undefined) isPremium = storageIsPremium
    return isPremium
}

// function getUser() {
//     const storageUser = sessionStorage.getItem('user');
//     if (!currUser && storageUser) currUser = storageUser
//     return currUser
// }

async function sendCode(phone) {
    const res = await httpService.post('customers/send_code/ucp', { phone })
    console.log(res)
    return res
}

async function login(phone, verifyCode) {
    const res = await httpService.post('customers/login', { phone, code: verifyCode })
    console.log(res)
    _setToken(res.data.token)
    _setIsPremium(res.data.info.isPremium)
    // user = res.data.info

    return res
}

async function getUser() {
    // get user by token 
    const res = await httpService.get('customers/me');
    return res.data;
}

function logout() {
    sessionStorage.setItem('token', '');
    currToken = '';
}

function _setToken(token) {
    currToken = token;
    sessionStorage.setItem('token', token)
}

function _setIsPremium(value) {
    isPremium = value;
    sessionStorage.setItem('isPremium', value);
}

// function _setUser(user) {
//     currUser = user;
//     sessionStorage.setItem('user', user);
// }



