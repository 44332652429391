


function timestampToDDMMYYYY(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return _addZeroIfNeed(day) + '/' + _addZeroIfNeed(month) + '/' + year
}

const timestampToStartDay = (timestamp) => {
    var start = new Date(timestamp);
    return start.setHours(0, 0, 0, 0, 0)
}

const timestampToEndDay = (timestamp) => {
    var start = new Date(timestamp);
    return start.setHours(23, 59, 59, 999).getTime();
}

function stopPropagation(ev) {
    ev.stopPropagation();
}

function _addZeroIfNeed(number) {
    return number < 10 ? '0' + number : '' + number;
}

function datePickerTimeToTimestamp(string) {
    const [year, month, day] = string.split('-');
    var newDate = new Date(year, month - 1, day);
    return newDate.getTime()
}

function timestampToDatePickerTime(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year + '-' + _addZeroIfNeed(month) + '-' + _addZeroIfNeed(day)
}



export const utiService = {
    timestampToDDMMYYYY,
    timestampToStartDay,
    timestampToEndDay,
    stopPropagation,
    datePickerTimeToTimestamp,
    timestampToDatePickerTime,
}


